import {observer} from 'mobx-react';
import React from 'react';
import Div100vh from '../../components/Div100vh';
import {useStore} from '../../stores/AppStore';
import EmptyServersListTitle from './EmptyServersListTitle';
import SearchBar from './SearchBar';
import SearchResultsBar from './SearchResultsBar';
import ServersTable from './ServersTable';
import TableColumnsSwitcher from './TableColumnsSwitcher';
import useServersTableColumns from './useServersTableColumns';

export const ServersPage: React.FC = observer(() => {
  const {instancesStore} = useStore();
  const {filteredInstances, instances, filter, isInit} = instancesStore;

  const {columns, toggleColumn, resetColumns} = useServersTableColumns();

  const hasServerInstances = !!instances.length;
  const isFoundInstances = !!filteredInstances.length;

  return (
    <Div100vh className="page">
      {hasServerInstances ? (
        <SearchBar>
          <TableColumnsSwitcher columns={columns} toggleColumn={toggleColumn} resetColumns={resetColumns} />
        </SearchBar>
      ) : null}

      <SearchResultsBar searchValue={filter.searchValue} foundAmount={filteredInstances.length} />

      <div className="page__inner">
        {hasServerInstances && isFoundInstances ? (
          <ServersTable instances={filteredInstances} columns={columns} />
        ) : !hasServerInstances ? (
          <EmptyServersListTitle isLoading={!isInit} />
        ) : null}
      </div>
    </Div100vh>
  );
});

export default ServersPage;
