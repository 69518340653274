import {observer} from 'mobx-react';
import React from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';
import {animated} from 'react-spring';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import Paths, {InstanceRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {Instance} from '../../stores/Instance';
import Accesses from './Accesses';
import Overview from './Overview';
import ServerInfoHead from './ServerInfoHead';
import ServerInfoTabs from './ServerInfoTabs';

export enum ServerInfoTab {
  OVERVIEW = 'overview',
  ACCESSES = 'accesses',
}

const tabsOrder = {
  [ServerInfoTab.OVERVIEW]: 1,
  [ServerInfoTab.ACCESSES]: 2,
};

function isTabValid(value: string): value is ServerInfoTab {
  return Object.values(ServerInfoTab).includes(value as ServerInfoTab);
}

interface IProps {
  instance: Instance;
}

const ServerInfoViewer: React.FC<IProps> = observer((props) => {
  const {plansStore} = useStore();
  const navigate = useNavigate();
  const params = useParams<InstanceRouteParams>();

  const [currentTab, setCurrentTab] = React.useState<ServerInfoTab>(ServerInfoTab.OVERVIEW);
  const prevTab = currentTab === ServerInfoTab.ACCESSES ? ServerInfoTab.OVERVIEW : ServerInfoTab.ACCESSES;

  React.useEffect(() => {
    if (params.tabType && isTabValid(params.tabType)) {
      setCurrentTab(params.tabType);
    }
  }, [params]);

  const handleChangeTab = (tab: ServerInfoTab) => {
    navigate(
      generatePath(Paths.ServerInfo, {
        instanceId: `${props.instance.id}`,
        tabType: tab,
      }),
    );
  };

  const handleBackButton = () => {
    navigate(Paths.Servers);
  };

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  const handleClickResizeServer = (instance: Instance) => {
    if (instance.plan) {
      plansStore.loadUpgradablePlansList(instance.plan);
      navigate(Paths.ResizeServer);
    }
  };

  return (
    <>
      <ServerInfoHead onBack={handleBackButton}>
        <ServerInfoTabs currentTab={currentTab} onChange={handleChangeTab} />
      </ServerInfoHead>

      <div className="page__inner">
        {transitions((styles, step) =>
          step === tabsOrder[ServerInfoTab.OVERVIEW] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <div className="server-info-tab">
                <div className="server-info-tab__body">
                  <Overview instance={props.instance} onClickResizeServer={handleClickResizeServer} />
                </div>
              </div>
            </animated.div>
          ) : step === tabsOrder[ServerInfoTab.ACCESSES] ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <div className="server-info-tab">
                <div className="server-info-tab__body">
                  <Accesses instance={props.instance} />
                </div>
              </div>
            </animated.div>
          ) : null,
        )}
      </div>
    </>
  );
});

export default ServerInfoViewer;
