import cn from 'classnames';
import {observer} from 'mobx-react';
import React, {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import SearchInput from '../../components/UI/SearchInput';
import {useStore} from '../../stores/AppStore';

interface ISearchBarProps {
  className?: string;
}

const SearchBar: React.FC<PropsWithChildren<ISearchBarProps>> = observer((props) => {
  const {instancesStore} = useStore();
  const {t} = useTranslation();
  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    instancesStore.filter.setSearchValue(e.target.value);
  };

  const handleClear = () => {
    instancesStore.filter.clearSearch();
  };

  const handleFocus = () => {
    setCollapsed(false);
  };

  const handleBlur = () => {
    setCollapsed(true);
  };

  return (
    <div className={cn('search-bar', props.className)}>
      <SearchInput
        value={instancesStore.filter.searchValue}
        className={cn('search-bar__search', {collapsed, 'search-bar__search--right-bar': !!props.children})}
        placeholder={t('page_head_search_placeholder')}
        onChange={handleSearch}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClear={handleClear}
      />
      <div className="search-bar__right">{props.children}</div>
    </div>
  );
});

export default SearchBar;
