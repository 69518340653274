import {observer} from 'mobx-react';
import React from 'react';
import {useParams} from 'react-router';
import Div100vh from '../../components/Div100vh';
import {navigateToDomains} from '../../routes/navigateTo';
import {DomainRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {DomainsPageTab} from '../DomainsPage/DomainsPage';
import DomainInfoViewer from './DomainInfoViewer';

export const DomainInfoPage: React.FC = observer(() => {
  const {domainsView} = useStore();
  const params = useParams<DomainRouteParams>();

  React.useEffect(() => {
    domainsView.openDomainByParams(params);
  }, [params, domainsView]);

  React.useEffect(() => {
    if (domainsView.activeDomain?.isError) {
      navigateToDomains(DomainsPageTab.MyDomains);
    }
  }, [domainsView.activeDomain]);

  if (!domainsView.activeDomain) {
    return null;
  }

  return (
    <Div100vh className="page page--domain-info">
      <DomainInfoViewer domain={domainsView.activeDomain} />
    </Div100vh>
  );
});

export default DomainInfoPage;
